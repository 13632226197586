@use "sass:map";
@use "sass:color";
@use "../../styles/global_variables" as gv;

.card {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-self: center;
    border-radius: 10px;
    background-color: lighten(map.get(gv.$colors, "background"), 15);
    box-shadow: 0px 1px 5px 1px darken(map.get(gv.$colors, "background"), 15);

    @media (min-width: gv.$desktop-width-min) {
        width: 30%;
        height: 100%;
    }

    a {
        text-decoration: none;
    }

    .card-text {
        display: flex;
        justify-content: space-between;
        margin-right: 15px;
        align-items: center;

        .project-title {
            font-size: 14px;
            margin-left: 15px;
            color: map.get(gv.$colors, "title");
            font-family: map.get(gv.$font-family, "text");

            @media (min-width: gv.$desktop-width-min) {
                font-size: 16px;
            }
        }
    
        .button {
            padding: 5px 20px;

            @media (min-width: gv.$desktop-width-min) {
                padding: 10px 30px;
            }
        }
    }

    .project-slideshow {
        position: absolute;
        border-radius: 10px;
        object-fit: cover;
    }

}
