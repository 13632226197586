@use "sass:map";
@use "sass:color";
@use "../../styles/global_variables" as gv;

.button {
    padding: 5px;
    margin: 5px auto;
    text-align: center;
    color: map.get(gv.$colors, "title");
    border-style: none;
    border-radius: 20px;
    background: linear-gradient(
    map.get(gv.$colors, "dark-color"),
    map.get(gv.$colors, "light-color")
    );
    transition: all 300ms;

    @media (min-width: gv.$desktop-width-min) {
        padding: 15px;
        margin: 10px auto;
        border-radius: 25px;
    }
    
    &:hover {
        box-shadow: 0px 3px 5px 0px map.get(gv.$colors, "background");
        cursor: pointer;
    }
}