@use "sass:map";
@use "sass:color";
@use "./global_variables" as gv;

.contact {
    background-color: map.get(gv.$colors, "background");
    color: map.get(gv.$colors, "text");
    padding: 25px;

    .tmp-contact-p {
        font-size: 18px;

        a {
            color: map.get(gv.$colors, "text");
        }
    }
}