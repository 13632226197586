@use "sass:map";
@use "sass:color";
@use "../../styles/global_variables" as gv;

.mini-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    // background-color: color.change(map.get(gv.$colors, "text"), $alpha: 0.2);
    background-color: lighten(map.get(gv.$colors, "background"), 10);;
    // border: solid 2px map.get(gv.$colors, "text");
    border-radius: 5px;
    width: 50px;
    height: auto;
    padding: 10px;
    transition-duration: 200ms;
    box-shadow: 0 3px 5px 0 darken(map.get(gv.$colors, "background"), 15);

    @media (min-width: gv.$desktop-width-min) {
        width: 64px;
    }

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 11px 5px 0 darken(map.get(gv.$colors, "background"), 15);
    }

    .icon {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: auto;
        width: 100%;
    }

    .name {
        text-align: center;
        color: map.get(gv.$colors, "text");
        margin-bottom: 0;
        font-size: 12px;
    }
}