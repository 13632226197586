@use "sass:map";
@use "sass:color";
@use "../styles/global_variables" as gv;

.project-page {
    background-color: map.get(gv.$colors, "background");
}

.project-page-title {
    text-align: center;
    color: map.get(gv.$colors, "title");
    font-family: map.get(gv.$font-family, "title");
    font-size: 18px;
    
    @media (min-width: gv.$desktop-width-min) {
        font-size: 32px;
    }
}

.project-section {
    padding: 25px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    
    @media (min-width: gv.$desktop-width-min) {
        flex-wrap: wrap;
        flex-direction: row;
    }
    
    .project-media-container {
        color: map.get(gv.$colors, "background");
        box-shadow: 0px 1px 5px 1px darken(map.get(gv.$colors, "background"), 15);
        
        @media (min-width: gv.$desktop-width-min) {
            width: 60%;
        }

        .project-iframe {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            min-height: 280px;

            @media (min-width: gv.$desktop-width-min) {
                min-height: 500px;
                background-color: white;
            }
        }
    }
    
    .project-description {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        background-color: lighten(map.get(gv.$colors, "background"), 15);
        // border: solid 2px map.get(gv.$colors, "dark-color");
        border-radius: 5px;
        box-shadow: 0px 1px 5px 1px darken(map.get(gv.$colors, "background"), 15);
        margin-top: 5px;
        
        @media (min-width: gv.$desktop-width-min) {
            width: 35%;
        }
        
        .description-title {
            margin: 10px;
            font-size: 14px;
            color: map.get(gv.$colors, "title");
            font-family: map.get(gv.$font-family, "text");
            
            @media (min-width: gv.$desktop-width-min) {
                font-size: 20px;
                margin: 20px;
            }
        }
        
        .description-content {
            font-size: 12px;
            margin: 10px;
            line-height: 22px;
            overflow: hidden;
            white-space: pre-line;
            color: map.get(gv.$colors, "text");
            font-family: map.get(gv.$font-family, "text");
            
            @media (min-width: gv.$desktop-width-min) {
                font-size: 14px;
                margin: 20px;
                
            }
        }
    }
}