@use "sass:map";
@use "sass:color";
@use "./global_variables" as gv;


body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
    font-family: map.get(gv.$font-family, "text");
    flex: 1;
    // margin: 7px 20px 7px 20px;
    
    
    // @media (min-width: gv.$desktop-width-min) {
        //     margin: 38px 80px 38px 80px;
        // }
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Fait en sorte que le contenu prenne au moins 100% de la hauteur de la fenêtre */
    margin: 0;
    width: 100%;
}
    
