@use "sass:map";
@use "sass:color";
@use "../../styles/global_variables" as gv;

.collapse {
    position: relative;
}

.top-bar {
    width: 100%;
    background: linear-gradient(
        map.get(gv.$colors, "dark-color"),
        map.get(gv.$colors, "light-color")
    );
    border-radius: 5px;
    border-style: none;
    padding: 10px;
    font-family: map.get(gv.$font-family, "title");
    font-size: 12px;
    color: white;
    text-align: left;
    transition: all 200ms ease-out;

    &:hover {
        box-shadow: 0px 3px 5px 0px darken(map.get(gv.$colors, "background"), 15);
    }

    .chevron {
        color: white;
        position: absolute;
        right: 10px;
        top: 10px; 
        transition: transform 200ms;
    }
    
    .down {
        transform: rotate(0deg);
    }
    
    .up {
        transform: rotate(-180deg);
    }
}

.collapse-content {
    background-color: lighten(map.get(gv.$colors, "background"), 15);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: map.get(gv.$colors, "text");
    font-family: map.get(gv.$font-family, "text");
    font-size: 12px;
    line-height: 15px;
    transition: height 300ms;
    overflow: hidden;

    .collapse-text {
        margin: 20px;
        white-space: pre-line;

        .file-line {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            column-gap: 20px;
            align-items: center;

            .file-link {
                color: map.get(gv.$colors, "text");
            }
        }
    }
}



@media (min-width: gv.$desktop-width-min) {

    .top-bar {
        font-size: 22px;

        .chevron {
            width: 24px;
            height: 24px;
        }
    }

    .collapse-content {
        font-size: 16px;
        line-height: 25px;
    }
}